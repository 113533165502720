import React, { useEffect, useState } from 'react';
import { Alert, Button, Tabs, Tab, Col, Row, Spinner, Card, Form, InputGroup } from 'react-bootstrap';
import PaymentHistoryForm from './PaymentHistoryPopup/PaymentHistoryForm';
import Comments from '../AccountDetailsPopup/AccordionItems/Comments';
import AdverseCarrierDropdown from './AdverseCarrier/AdverseCarrierDropdown';
import CollectorDropdown from './CollectorDropdown';
import APIUpdatingCheckbox from './APIUpdatingCheckbox';
import SIFAuthButton from './SIFAuthButton';
import CurrencyInput from 'react-currency-input-field';
import ReactSelect from 'react-select';
import FormCol from './FormCol';
import FormRow from './FormRow';
import useFetch from '../Utilities/useFetch';
import NCDDropdown from './NCDDropdown';
import SkipTraceToggleCheckbox from './SkipTraceToggleCheckbox';
import AdverseCarrierForm from './AdverseCarrier/AdverseCarrierForm';
import StatusChangeModal from './StatusChangeModal';
import { useMsal } from '@azure/msal-react';
import funcFetch from '../Utilities/funcFetch';
import ErrorBoundary from '../Auth/ErrorBoundary';

const CollectorClaimView = ({
    claimNumber,
    loading,
    activities,
    setActivities,
    account,
    setAccount,
    updateNCDAndActivities
}) => {
    const {REACT_APP_API_URL} = process.env;
    const {data: isPaymentAdmin, loading: loadingIsPaymentAdmin} = useFetch(true,`${REACT_APP_API_URL}/Claims/IsPaymentAdmin`,'GET')
    const {data:projects,loading:loadingProjects} = useFetch(true,`${REACT_APP_API_URL}/Claims/GetProjects`,'GET')
    const {data: isCollectorAdmin} = useFetch(true,`${REACT_APP_API_URL}/Claims/IsCollectorAdmin`,'GET')
    const {data: isClaimCreator} = useFetch(true,`${REACT_APP_API_URL}/Claims/isClaimCreator`,'GET')
    const [projectOptions, setProjectOptions] = useState();
    const [selectedClient] = useState();
    const { instance, accounts } = useMsal();
    const [selectedAdverseCarrier, setSelectedAdverseCarrier] = useState();
    const [statusChangeIsOpen,setStatusChangeIsOpen] = useState(false);
    const [postingData, setPostingData] = useState(false);
    const [APIResponse, setAPIResponse] = useState();
    const [APIError, setAPIError] = useState();
    const [readOnly] = useState(false)
    const [ROReadOnly] = useState(false)
    let formatting_options = {
        style: 'currency',
        currency: 'USD',
     }
    let dollarString = new Intl.NumberFormat("en-US", formatting_options);

    const [key, setKey] = useState('general');
    const today = new Date().toISOString().split('T')[0];
    const aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0];
    const fiveYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString().split('T')[0];

    useEffect(() => {
        if(projects){
            setProjectOptions(projects.map(project => { return { value: project.key, label: project.value } }));
        }
    }, [projects]);

    useEffect(()=>{
        if(claimNumber){
            setAPIError(null)
            setAPIResponse(null)
            setSelectedAdverseCarrier(null)
        }
    },[claimNumber])

    const handleChange = (valueName, value) => {
        if(valueName.toLowerCase().includes('date')&&value===''){
            value = null;
        }
        setAPIError(null)
        setAPIResponse(null)
        let newAccountObj = {...account}
        newAccountObj.account[valueName] = value;
        setAccount(newAccountObj)
    }

    const handleFinancialChange = (valueName,value) =>{
        setAPIError(null)
        setAPIResponse(null)
        let newAccountObj = {...account}
        newAccountObj.account[valueName] = value;
        newAccountObj.financial[valueName] = value;
        setAccount(newAccountObj)
    }

    const handleSubmit = (e) => {
        setPostingData(true)
        setAPIError(null)
        setAPIResponse(null)
        e.preventDefault()
        if(claimNumber){
            funcFetch(`${REACT_APP_API_URL}/Claims/PostAccount`,null,'POST',{account:account.account,financial:account.financial}, instance, accounts)
            .then(response=>{
                setPostingData(false)
                setAPIResponse('Successfully saved changes')
            })
            .catch(err=>{
                console.error(err)
                setAPIError('There was an issue, please try again.')
                setPostingData(false)
            })
        }else{
            funcFetch(`${REACT_APP_API_URL}/Claims/GenerateDebtorid`,{clientID: selectedClient.value.clientID, clientRefNo: account.account.clientRefNo},'POST', {}, instance, accounts)
            .then(debtorID=>{
                account.account.debtorID = debtorID
                setAccount(account)
                funcFetch(`${REACT_APP_API_URL}/Claims/PostAccount`,null,'POST',{account:account.account,financial:account.financial}, instance, accounts)
                .then(response=>{
                    setPostingData(false)
                })
                .catch(error=>{
                    setAPIError('There was an issue, please try again.')
                    console.error(error)
                    setPostingData(false)
                })
            })
        }
    }

    return (
        <>
        {statusChangeIsOpen?<StatusChangeModal show={statusChangeIsOpen} setShow={setStatusChangeIsOpen} account={account} updateNCDAndActivities={updateNCDAndActivities} />:null}
        <ErrorBoundary>
            <Row className='claim-lookup-wrapper'>
                <Col>
                    <form id='claim-info' onSubmit={(e)=>handleSubmit(e)}>
                            {loading?
                                <Alert>Getting claim info <Spinner animation='border' size='sm' /></Alert>
                            :account.financial&&
                            <>
                                <FormRow className='light-grey-background'>
                                    <FormCol variant='sm'>
                                        <Form.Label>Skip Trace</Form.Label>
                                        <SkipTraceToggleCheckbox
                                            readOnly={readOnly}
                                            defaultChecked={account.accountFlags?.skipTraceNeeded}
                                            debtorid={account.account?.debtorID}
                                            updateNCDAndActivities={updateNCDAndActivities}
                                        />
                                    </FormCol>
                                </FormRow>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="general" title="General">
                                        <FormRow className='dark-background'>
                                            <FormCol>
                                                <Form.Label>Claimant Last</Form.Label>
                                                <input disabled={readOnly} value={account.account?.claimantName1Last} onChange={(e)=>handleChange('claimantName1Last',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Claimant First</Form.Label>
                                                <input disabled={readOnly} value={account.account?.claimantName1First} onChange={(e)=>handleChange('claimantName1First',e.target.value)} className='input-small' />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Addr</Form.Label>
                                                <Form.Control disabled={readOnly} value={account.account?.address} onChange={(e)=>handleChange('address',e.target.value)} />
                                            </FormCol>                      
                                            <FormCol>
                                                <Form.Label>City</Form.Label>
                                                <input disabled={readOnly} value={account.account?.city} onChange={(e)=>handleChange('city',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>State</Form.Label>
                                                <StatesDropdown className='input-small full-width black-text' value={account.account?.state} onChange={(e)=>handleChange('state',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Zip</Form.Label>
                                                <input disabled={readOnly} value={account.account?.zip} onChange={(e)=>handleChange('zip',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>DOB</Form.Label>
                                                <input className={(account.account.age<18)?'red-background':''} disabled={readOnly} type='date' value={account.account?.dateOfBirth?.split('T')[0]} onChange={(e)=>handleChange('dateOfBirth',e.target.value)} max={today} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>% Liab</Form.Label>
                                                <input className={(account.account.percentOfLiability===100)?'green-background':''} disabled={readOnly} value={account.account?.percentOfLiability} onChange={(e)=>handleChange('percentOfLiability',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>DL#</Form.Label>
                                                <input disabled={readOnly} value={account.account?.driversLicense} onChange={(e)=>handleChange('driversLicense',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Collector</Form.Label>
                                                {(isCollectorAdmin||isClaimCreator)?
                                                    <CollectorDropdown
                                                        className='payment-form-input black-text'
                                                        collector={account.account.collector}
                                                        readOnly={readOnly}
                                                        debtorID={account.account.debtorID}
                                                        updateNCDAndActivities={updateNCDAndActivities}
                                                    />
                                                :
                                                    <input disabled={true} value={account.account?.collector} onChange={(e)=>handleChange('collector',e.target.value)} />
                                                }
                                            </FormCol>
                                        </FormRow>

                                        <FormRow className='light-grey-background'>
                                            <FormCol>
                                                <Form.Label>RO (last)</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.claimantRONameLast} onChange={(e)=>handleChange('claimantRONameLast',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>(f)</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.claimantRONameFirst} onChange={(e)=>handleChange('claimantRONameFirst',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>RO DOB</Form.Label>
                                                <input type='date' disabled={ROReadOnly} value={account.account?.roDateOfBirth?.split('T')[0]} onChange={(e)=>handleChange('roDateOfBirth',e.target.value)} max={today}/>
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>RO Address</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.roAddress} onChange={(e)=>handleChange('roAddress',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>RO City</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.roCity} onChange={(e)=>handleChange('roCity',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>RO State</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.roState} onChange={(e)=>handleChange('roState',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>RO Zip</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.rozip} onChange={(e)=>handleChange('rozip',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Plate#</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.plateNo} onChange={(e)=>handleChange('plateNo',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Make</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.vehicleMake} onChange={(e)=>handleChange('vehicleMake',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>YR</Form.Label>
                                                <input disabled={ROReadOnly} value={account.account?.yr} onChange={(e)=>handleChange('yr',e.target.value)} />
                                            </FormCol>
                                        </FormRow>

                                        <FormRow className='black-outline'>
                                            <FormCol>
                                                <Form.Label>Principle</Form.Label>
                                                <input disabled={!isClaimCreator} value={account.financial?.principle?dollarString.format(account.financial.principle):0} onChange={(e)=>handleFinancialChange('principle',e.target.value)} />
                                            </FormCol>
                                            <FormCol className='full-width'>
                                                <Form.Label>Comments</Form.Label>
                                                <Form.Control as='textarea' disabled={readOnly} value={account.account?.comments} onChange={(e)=>handleChange('comments',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Project ID</Form.Label>
                                                {/* should be a dropdown and utilize GetProjects() */}
                                                {loadingProjects ?
                                                    <Spinner animation="border" role="status" />
                                                : projectOptions ?
                                                    <ReactSelect
                                                    className='full-width'
                                                        value={(account.account?.projectID)?projectOptions.find(project=>project.value===account.account?.projectID):undefined}
                                                        onChange={(e)=>handleChange('projectID',e.value)}
                                                        options={projectOptions}
                                                        isDisabled={!isClaimCreator}
                                                    />
                                                : null}
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>NCD</Form.Label>
                                                <NCDDropdown 
                                                    defaultDate={account.account?.nextContactDate} 
                                                    onChange={(e)=>handleChange('nextContactDate',e.target.value)} 
                                                    debtorid={account.account?.debtorID}
                                                    account={account}
                                                    setAccount={setAccount}
                                                    updateNCDAndActivities={updateNCDAndActivities}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Status</Form.Label>
                                                <InputGroup>
                                                    <Form.Control disabled value={account.account?.debtorStatus} />
                                                    <Button variant="secondary" onClick={()=>setStatusChangeIsOpen(true)}>Change Status</Button>
                                                </InputGroup>
                                            </FormCol>                                                
                                            <FormCol>
                                                <Form.Label>Status Date</Form.Label>
                                                <Form.Control readOnly value={account.account?.statusDate?.split('T')[0]} max={today} type='date' />
                                            </FormCol>
                                            <FormCol className='full-width'>
                                                <Form.Label>Loss Desc</Form.Label>
                                                <Form.Control as='textarea' maxLength={250} value={account.account?.lossDescriptions} onChange={(e)=>handleChange('lossDescriptions',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Gov Statute Date</Form.Label>
                                                <Form.Control value={account.account?.govStatuteDate?.split('T')[0]} max={fiveYearsFromNow} type='date' onChange={(e)=>handleChange('govStatuteDate',e.target.value)}  />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Gov Statute Desc</Form.Label>
                                                <input value={account.account?.govStatuteDesc} onChange={(e)=>handleChange('govStatuteDesc',e.target.value)} maxLength={500}/>
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>List Date</Form.Label>
                                                <Form.Control readOnly value={account.account?.listDate?.split('T')[0]} onChange={(e)=>handleChange('listDate',e.target.value)} max={today} type='date' />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Adj</Form.Label>
                                                <input disabled={!isClaimCreator} value={account.account?.insuranceAdjuster} onChange={(e)=>handleChange('insuranceAdjuster',e.target.value)} />
                                            </FormCol>   
                                            <FormCol>
                                                <Form.Label>DOL</Form.Label>
                                                <Form.Control value={account.account?.serviceDate?.split('T')[0]} onChange={(e)=>handleChange('serviceDate',e.target.value)} max={today} type='date' />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>State Of Loss</Form.Label>
                                                <input disabled={!isClaimCreator} value={account.account?.stateOfLoss} onChange={(e)=>handleChange('stateOfLoss',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>SOL BI</Form.Label>
                                                <input 
                                                    disabled={true} 
                                                    value={account.limits?.biDays} 
                                                    className={
                                                        account.limits?.biDays&&
                                                        (account.limits.biDays<=90&&account.limits.biDays>30)?'yellow-background':
                                                        (account.limits.biDays<=30)?'red-background':''
                                                    } />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>SOL PD</Form.Label>
                                                <input 
                                                    disabled={true} 
                                                    value={account.limits?.pdDays} 
                                                    className={
                                                        account.limits?.pdDays&&
                                                        (account.limits.pdDays<=90&&account.limits.pdDays>30)?'yellow-background':
                                                        (account.limits.pdDays<=30)?'red-background':''
                                                    } />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>IV</Form.Label>
                                                <input disabled={readOnly} value={account.account?.insuredVehicle} onChange={(e)=>handleChange('insuredVehicle',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Ins Last</Form.Label>
                                                <input disabled={readOnly} value={account.account?.insuredLastName} onChange={(e)=>handleChange('insuredLastName',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Ins First</Form.Label>
                                                <input disabled={readOnly} value={account.account?.insuredFirstName} onChange={(e)=>handleChange('insuredFirstName',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Loss Loc</Form.Label>
                                                <input disabled={readOnly} value={account.account?.rentalBillDraft} onChange={(e)=>handleChange('rentalBillDraft',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Admin Reviewed</Form.Label>
                                                <Form.Check readOnly={readOnly} checked={account.account?.adminReviewed} onChange={(e)=>handleChange('adminReviewed',e.target.checked)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>MR</Form.Label>
                                                <APIUpdatingCheckbox 
                                                    readOnly={readOnly} 
                                                    checked={account.account?.mr} 
                                                    updateNCDAndActivities={updateNCDAndActivities} 
                                                    debtorID={account.account?.debtorID}
                                                    APIUrl='/Claims/PostMR'
                                                    fieldName='mr'
                                                    />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Ins Found</Form.Label>
                                                <Form.Check readOnly checked={account.account?.insuredLastName?true:false} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Ext Rqstd</Form.Label>
                                                <APIUpdatingCheckbox 
                                                    readOnly={readOnly} 
                                                    checked={account.account?.witness} 
                                                    updateNCDAndActivities={updateNCDAndActivities} 
                                                    debtorID={account.account?.debtorID}
                                                    APIUrl='/Claims/PostExtRqst'
                                                    fieldName='witness'
                                                    />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>PTY</Form.Label>
                                                <APIUpdatingCheckbox 
                                                    readOnly={readOnly} 
                                                    checked={account.account?.prpty} 
                                                    updateNCDAndActivities={updateNCDAndActivities} 
                                                    debtorID={account.account?.debtorID}
                                                    APIUrl='/Claims/PostPTY'
                                                    fieldName='prpty'
                                                    />
                                            </FormCol>                            
                                            <FormCol>
                                                <Form.Label>RO PTY</Form.Label>
                                                <APIUpdatingCheckbox 
                                                    readOnly={readOnly} 
                                                    checked={account.account?.roprpty} 
                                                    updateNCDAndActivities={updateNCDAndActivities} 
                                                    debtorID={account.account?.debtorID}
                                                    APIUrl='/Claims/PostROPTY'
                                                    fieldName='roprpty'
                                                    />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>RO MR</Form.Label>
                                                <APIUpdatingCheckbox
                                                    readOnly={readOnly}
                                                    checked={account.account?.rO_MR}
                                                    updateNCDAndActivities={updateNCDAndActivities}
                                                    debtorID={account.account?.debtorID}
                                                    APIUrl='/Claims/PostROMR'
                                                    fieldName='rO_MR'
                                                />
                                            </FormCol>
                                            
                                            <FormCol>
                                                <Form.Label>PR</Form.Label>
                                                <APIUpdatingCheckbox 
                                                    readOnly={readOnly} 
                                                    checked={account.account?.policeReport} 
                                                    updateNCDAndActivities={updateNCDAndActivities} 
                                                    debtorID={account.account?.debtorID}
                                                    APIUrl='/Claims/PostPR'
                                                    fieldName='policeReport'
                                                    />
                                            </FormCol>    
                                        </FormRow>

                                        <FormRow>
                                            <Button type='submit'>{postingData?<Spinner animation='border' size='sm'/>:'Save Changes'}</Button>
                                            {APIResponse?
                                            <Alert>{APIResponse}</Alert>
                                            :null}

                                            {APIError?
                                            <Alert variant='warning'>{APIError}</Alert>
                                            :null}
                                        </FormRow>
                                    </Tab>

                                    <Tab eventKey="financial" title="Financial">
                                        <FormRow className='black-outline'>
                                            <FormCol>
                                                <Form.Label>Amount Due</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="amountDue"
                                                    value={account.financial?.amountDue?account.financial?.amountDue:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Principle</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="principle"
                                                    value={account.financial?.principle?account.financial?.principle:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Collection Rate</Form.Label>
                                                <input disabled={!isClaimCreator} value={account.account?.collectionRate} onChange={(e)=>handleChange('collectionRate',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Principle Adjustments</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="principleAdjustments"
                                                    value={account.financial?.principleAdjustments?account.financial?.principleAdjustments:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Deductible</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="deductible"
                                                    value={account.account.deductible?account.account.deductible:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Total Paid</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="amountPaid"
                                                    value={account.financial.amountPaid?account.financial.amountPaid:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>OOP</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="outofPocket"
                                                    value={account.financial.outofPocket?account.financial.outofPocket:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>{account.account?.projectID?.toLowerCase()==='contribution uim'?'Offer':'Salvage'}</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="salvage"
                                                    value={account.account.salvage?account.account.salvage:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Tow</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="towCharge"
                                                    value={account.account.towCharge?account.account.towCharge:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Rental</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="rental"
                                                    value={account.account.rental?account.account.rental:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                    disabled={!isClaimCreator}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>Collision Amount</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="collision"
                                                    value={account.account.collision?account.account.collision:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                />
                                            </FormCol>   
                                            <FormCol>
                                                <Form.Label>UMBI Amount</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="umbi"
                                                    value={account.account.umbi?account.account.umbi:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                />
                                            </FormCol>   
                                            <FormCol>
                                                <Form.Label>PTP Amount</Form.Label>
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="ptpAmt"
                                                    value={account.account.ptpAmt?account.account.ptpAmt:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>PTP Date</Form.Label>
                                                <Form.Control disabled={readOnly} value={account.account?.ptpDate?.split('T')[0]} max={aYearFromNow} type='date' onChange={(e)=>handleChange('ptpDate',e.target.value)} />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>SIF Amount</Form.Label>
                                                
                                                <CurrencyInput
                                                    prefix='$'
                                                    name="prinBal"
                                                    value={account.account.prinBal?account.account.prinBal:0}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleFinancialChange(name,value)}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>SIF Auth</Form.Label>
                                                <SIFAuthButton 
                                                    sifauth={account.account.sifauth}
                                                    debtorID={account.account.debtorID}
                                                    updateNCDAndActivities={updateNCDAndActivities}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Form.Label>SIF%</Form.Label>
                                                <input 
                                                    disabled={!isClaimCreator} 
                                                    value={account.account.prinBal?`${(Math.round((account.account.prinBal * 100)/account.financial.principle))}%`:'0.00%'} 
                                                    onChange={(e)=>handleChange('prinBal',e.target.value)}
                                                    />
                                            </FormCol>
                                        </FormRow>
                                        <FormRow>
                                                                                        <Button type='submit'>{postingData?<Spinner animation='border' size='sm'/>:'Save Changes'}</Button>
                                            {APIResponse?
                                            <Alert>{APIResponse}</Alert>
                                            :null}

                                            {APIError?
                                            <Alert variant='warning'>{APIError}</Alert>
                                            :null}
                                        </FormRow>
                                    </Tab>

                                    <Tab eventKey="adverseCarrier" title="Adverse Carrier">
                                        <FormRow className='adverse-carrier-dropdown'>
                                            <Form.Label>Adverse Carrier</Form.Label>
                                            <AdverseCarrierDropdown 
                                                adverseCarriers={account.adverseCarriers}
                                                selectedAdverseCarrier={selectedAdverseCarrier}
                                                setSelectedAdverseCarrier={setSelectedAdverseCarrier}
                                                />
                                            <Button onClick={()=>setSelectedAdverseCarrier({value:{
                                                "adverseCarrierID": 0,
                                                "debtorid": account.account.debtorID,
                                                "adverseCarrier": "",
                                                "adverseAdjuster": "",
                                                "address1": "",
                                                "address2": "",
                                                "city": "",
                                                "state": "",
                                                "zip": "",
                                                "tel1": "",
                                                "ext1": "",
                                                "tel2": "",
                                                "ext2": "",
                                                "fax": "",
                                                "claim": "",
                                                "tpa": "",
                                                "email": "",
                                                "email1": "",
                                                "email2": "",
                                                "subDemandSent": false,
                                                "policyHolder": "",
                                                "denialOfCoverage": false,
                                                "denialOfLiability": false
                                                }})}>Add New</Button>
                                        </FormRow>
                                        {selectedAdverseCarrier?
                                            <AdverseCarrierForm
                                                className={'black-outline'}    
                                                adverseCarrier={selectedAdverseCarrier.value}
                                                updateNCDAndActivities={updateNCDAndActivities}
                                            />
                                        :null}
                                        <FormRow>
                                            {APIResponse?
                                            <Alert>{APIResponse}</Alert>
                                            :null}

                                            {APIError?
                                            <Alert variant='warning'>{APIError}</Alert>
                                            :null}
                                        </FormRow>
                                    </Tab>
                                </Tabs>
                        </>}
                    </form>
                </Col>
                <Col>
                    {claimNumber?
                    <div className='margin-top margin-bottom'>
                        <div>
                        {activities ?
                            
                            <Card>
                                <Comments 
                                    activities={activities} 
                                    setActivities={setActivities}
                                    debtorID={account.account?.debtorID} 
                                    updateNCDAndActivities={updateNCDAndActivities}
                                    />
                            </Card>
                            :
                            <Spinner animation='border' />
                        }
                        </div>
                        <Card className='payment-history-wrapper margin-top'>
                        {(account.paymentHistory&&projects&&!loadingIsPaymentAdmin)?
                        account.paymentHistory.map(payment=>{
                            return(
                                <div className='individual-payment-wrapper'>
                                    <PaymentHistoryForm 
                                        defaultFormData={payment} 
                                        projects={projects}
                                        loadingProjects={loadingProjects}
                                        readOnly={(isPaymentAdmin||isClaimCreator)?false:true}
                                        debtorID={account.account.debtorID}
                                        updateNCDAndActivities={updateNCDAndActivities}
                                        />
                                </div>
                                )
                        })
                        :null}
                        </Card>
                    </div>
                    :
                    null
                    }
                </Col>
            </Row>
        </ErrorBoundary>
        </>
    );
};

function StatesDropdown({className, value, onChange}){
    const [options] = useState([
        {
            value: "AL",
            label: "AL"
        },
        {
            value: "AK",
            label: "AK"
        },
        {
            value: "AZ",
            label: "AZ"
        },
        {
            value: "AR",
            label: "AR"
        },
        {
            value: "CA",
            label: "CA"
        },
        {
            value: "CO",
            label: "CO"
        },
        {
            value: "CT",
            label: "CT"
        },
        {
            value: "DC",
            label: "DC"
        },
        {
            value: "DE",
            label: "DE"
        },
        {
            value: "FL",
            label: "FL"
        },
        {
            value: "GA",
            label: "GA"
        },
        {
            value: "HI",
            label: "HI"
        },
        {
            value: "ID",
            label: "ID"
        },
        {
            value: "IL",
            label: "IL"
        },
        {
            value: "IN",
            label: "IN"
        },
        {
            value: "IA",
            label: "IA"
        },
        {
            value: "KS",
            label: "KS"
        },
        {
            value: "KY",
            label: "KY"
        },
        {
            value: "LA",
            label: "LA"
        },
        {
            value: "ME",
            label: "ME"
        },
        {
            value: "MD",
            label: "MD"
        },
        {
            value: "MA",
            label: "MA"
        },
        {
            value: "MI",
            label: "MI"
        },
        {
            value: "MN",
            label: "MN"
        },
        {
            value: "MS",
            label: "MS"
        },
        {
            value: "MO",
            label: "MO"
        },
        {
            value: "MT",
            label: "MT"
        },
        {
            value: "NE",
            label: "NE"
        },
        {
            value: "NV",
            label: "NV"
        },
        {
            value: "NH",
            label: "NH"
        },
        {
            value: "NJ",
            label: "NJ"
        },
        {
            value: "NM",
            label: "NM"
        },
        {
            value: "NY",
            label: "NY"
        },
        {
            value: "NC",
            label: "NC"
        },
        {
            value: "ND",
            label: "ND"
        },
        {
            value: "OH",
            label: "OH"
        },
        {
            value: "OK",
            label: "OK"
        },
        {
            value: "OR",
            label: "OR"
        },
        {
            value: "PA",
            label: "PA"
        },
        {
            value: "RI",
            label: "RI"
        },
        {
            value: "SC",
            label: "SC"
        },
        {
            value: "SD",
            label: "SD"
        },
        {
            value: "TN",
            label: "TN"
        },
        {
            value: "TX",
            label: "TX"
        },
        {
            value: "UT",
            label: "UT"
        },
        {
            value: "VT",
            label: "VT"
        },
        {
            value: "VA",
            label: "VA"
        },
        {
            value: "WA",
            label: "WA"
        },
        {
            value: "WV",
            label: "WV"
        },
        {
            value: "WI",
            label: "WI"
        },
        {
            value: "WY",
            label: "WY"
        }
    ])

    function handleChange(e){
        onChange({target:{
            value:e.value
        }})
    }
    return(
        <ReactSelect className={className} options={options} value={options.find(option=>option.value?.toLowerCase()===value?.toLowerCase())} onChange={(e)=>handleChange(e)} />
    )
}

export default CollectorClaimView;