import React, { useEffect, useState } from 'react'
import FormRow from '../FormRow';
import FormCol from '../FormCol';
import Form from 'react-bootstrap/Form';
import { Alert, Button, Spinner } from 'react-bootstrap';
import FuncFetch from '../../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';

function AdverseCarrierForm({
    adverseCarrier,
    className,
    updateNCDAndActivities
}) {
    const { instance, accounts } = useMsal();
    const { REACT_APP_API_URL } = process.env;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [ACInfo, setACInfo] = useState();
    const [successfullyPosted, setSuccessfullyPosted] = useState(false)

    useEffect(()=>{
        resetForm()
        if(adverseCarrier){
            let newACInfo = {
                ...adverseCarrier,
            }
            setACInfo(newACInfo)
            setSuccessfullyPosted(false)
            setError(null)
        }else{
            setACInfo(null)
        }
        
    // eslint-disable-next-line
    },[adverseCarrier])

    const resetForm = () => {
        let inputs = document.getElementsByClassName('ACInput')
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].value = ''
        }
    }

    const handleCheck = (e, fieldName) => { 
        setSuccessfullyPosted(false)
        let newInfo = {...ACInfo}
        newInfo[fieldName] = e.target.checked
        setACInfo(newInfo)
     }

    const handleChange = (e, fieldName) => {
        setSuccessfullyPosted(false)
        let newInfo = {...ACInfo}
        newInfo[fieldName] = e.target.value
        setACInfo(newInfo)
    }

    const testEmailValidity = (e, fieldName) => {
        setError(null)
        if(validateEmail(e.target.value)||e.target.value===''){
            let newInfo = {...ACInfo}
            newInfo[fieldName] = e.target.value
            setACInfo(newInfo)
        }else{
            setError(`${fieldName} is invalid`)
        }
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const testPhoneValidity = (e, fieldName) => { 
        setError(null)
        if(validateUSPhoneNumber(e.target.value)||e.target.value===''){
            let newInfo = {...ACInfo}
            newInfo[fieldName] = e.target.value
            setACInfo(newInfo)
        }else{
            setError(`${fieldName} is invalid`)
     }
    }
    
    function validateUSPhoneNumber(phoneNumber) {
        const regex = /^(1\s?)?(\+?1|1)?[.-]?\(?[2-9][0-9]{2}\)?[.-]?\s?[2-9][0-9]{2}[.-]?\s?[0-9]{4}$/;
        return regex.test(phoneNumber);
      }

    const handleSubmit = (e) => {
        setLoading(true)
        FuncFetch(`${REACT_APP_API_URL}/Claims/PostAdverseCarrier`,null,'POST',ACInfo,instance,accounts)
        .then(data=>{
            setLoading(false)
            setSuccessfullyPosted(true)
            updateNCDAndActivities(data)
        })
        .catch(error=>{
            setLoading(false)
            console.error(error)
            setError('There was an issue, please try again.')
        })
    }


  return (
    ACInfo&&
        <FormRow className={className}>
            <FormCol>
                <Form.Label>Adverse Carrier</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'adverseCarrier')} value={ACInfo.adverseCarrier} />
            </FormCol>
            <FormCol>
                <Form.Label>Address</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'address1')} value={ACInfo.address1} />
            </FormCol>
            <FormCol>
                <Form.Label>City</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'city')} value={ACInfo.city} />
            </FormCol>
            <FormCol>
                <Form.Label>State</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'state')} value={ACInfo.state} />
            </FormCol>
            <FormCol>
                <Form.Label>Zip</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'zip')} value={ACInfo.zip} />
            </FormCol>
            <FormCol>
                <Form.Label>Claim</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'claim')} value={ACInfo.claim} />
            </FormCol>
            <FormCol>
                <Form.Label>Policy Number</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'email')} value={ACInfo.email} />
            </FormCol>
            <FormCol>
                <Form.Label>Adjuster</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'adverseAdjuster')} value={ACInfo.adverseAdjuster} />
            </FormCol>
            <FormCol>
                <Form.Label>Email</Form.Label>
                <input className='ACInput' onBlur={(e)=> testEmailValidity(e,'email1')} onChange={(e)=>handleChange(e,'email1')} value={ACInfo.email1} />
            </FormCol>
            <FormCol>
                <Form.Label>Email 2</Form.Label>
                <input className='ACInput' onBlur={(e)=> testEmailValidity(e,'email2')} onChange={(e)=>handleChange(e,'email2')} value={ACInfo.email2} />
            </FormCol>
            <FormCol>
                <Form.Label>Phone</Form.Label>
                <input className='ACInput' onBlur={(e)=> testPhoneValidity(e,'tel1')} onChange={(e)=> handleChange(e,'tel1')} value={ACInfo.tel1} />
            </FormCol>
            <FormCol>
                <Form.Label>Ext</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'ext1')} value={ACInfo.ext1} />
            </FormCol>
            <FormCol>
                <Form.Label>Phone2</Form.Label>
                <input className='ACInput' onBlur={(e)=> testPhoneValidity(e,'tel2')} onChange={(e)=> handleChange(e,'tel2')} value={ACInfo.tel2} />
            </FormCol>
            <FormCol>
                <Form.Label>Ext.2</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'ext2')} value={ACInfo.ext2} />
            </FormCol>
            <FormCol>
                <Form.Label>Adjuster Fax</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'fax')} value={ACInfo.fax} />
            </FormCol>
            <FormCol>
                <Form.Label>Policy Holder</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'policyHolder')} value={ACInfo.policyHolder} />
            </FormCol>
            <FormCol>
                <Form.Label>TPA</Form.Label>
                <input className='ACInput' onChange={(e)=> handleChange(e,'tpa')} value={ACInfo.tpa} />
            </FormCol>
            <FormCol>
                <Form.Label>Denial of Coverage</Form.Label>
                <Form.Check className='ACInput' onClick={(e)=>handleCheck(e,"denialOfCoverage")} checked={ACInfo.denialOfCoverage} />
            </FormCol>
            <FormCol>
                <Form.Label>Denial of Liability</Form.Label>
                <Form.Check className='ACInput' onClick={(e)=>handleCheck(e,"denialOfLiability")} checked={ACInfo.denialOfLiability} />
            </FormCol>
            <FormCol>
                <Form.Label>Sub Demand Sent</Form.Label>
                <Form.Check className='ACInput' onClick={(e)=>handleCheck(e,"subDemandSent")} checked={ACInfo.subDemandSent} />
            </FormCol>
            <Button onClick={(e)=>handleSubmit(e)} disabled={successfullyPosted}>{loading?<Spinner animation='border' size='sm' />:successfullyPosted?'Changes Saved':'Save AC Changes'}</Button>
            {error?
            <Alert variant='warning'>{error}</Alert>
            :null}
        </FormRow>
    
  )
}

export default AdverseCarrierForm