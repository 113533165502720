import React, { useState, useEffect, useContext } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "../SignOutButton";
import './Header.css'
import { loginRequest } from '../../authConfig';
import { callMsGraph } from '../../graph';
import { useMsal } from '@azure/msal-react';
import { Row, Col, NavDropdown } from 'react-bootstrap';
import CustomerContext from '../Auth/CustomerContext';
import AddClaimPopup from '../Claims/AddClaimPopup';
import CollectorClaimModal from '../Claims/NCD/CollectorClaimModal';
import useFetch from '../Utilities/useFetch';

function Header() {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [ graphData, setGraphData ] = useState(null);
    const {cookies} = useContext(CustomerContext)
    const [newClaimFormIsOpen, setNewClaimFormIsOpen] = useState(false)
    const [claimFormIsOpen, setClaimFormIsOpen] = useState(false)
    const {claimNumber, setClaimNumber} = useContext(CustomerContext);
    const {data: isClaimCreator} = useFetch(true,`${process.env.REACT_APP_API_URL}/Claims/isClaimCreator`,'GET')

    useEffect(()=>{
        if(claimNumber){
            setClaimFormIsOpen(true)
        }
    // eslint-disable-next-line
    },[claimNumber])

    useEffect(()=>{
        if(!graphData && isAuthenticated && accounts?.length > 0){
            const handleLogout = () => {
                instance.logoutRedirect({postLogoutRedirectUri: '/login'})
                  .catch(e => {
                    console.error("Logout failed:", e);
                  });
              };
            instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
                forceRefresh: true
            })
            .then((response) => {
                callMsGraph(response.accessToken)
                .then((response) =>{
                    setGraphData(response)
                    });
            })
            .catch((error)=>{
                console.error('There was an issue acquiring the silent token',error)
                cookies.remove('customer')
                handleLogout()
            })
        }
    // eslint-disable-next-line
    },[graphData, accounts, instance, isAuthenticated])
    
  return (
    <>
    {newClaimFormIsOpen&&<AddClaimPopup show={newClaimFormIsOpen} handleClose={()=>setNewClaimFormIsOpen(false)} />}
    {claimFormIsOpen&&<CollectorClaimModal show={claimFormIsOpen} setShow={()=>setClaimFormIsOpen(false)} selectedClaim={claimNumber} setSelectedClaim={setClaimNumber}/>}
    <Navbar bg="dark" variant='dark'>
        <Container>
            <Navbar.Brand href="/">RMS Staff</Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={isAuthenticated?"navigation-wrapper":"logged-out-navigation-wrapper"}>
                    {isAuthenticated?
                    <>
                        <NavDropdown title="Claims">
                            <NavDropdown.Item href="/Claims/OpenClaims">Claims Search</NavDropdown.Item>
                            <NavDropdown.Item href="/Claims/NCD">NCD</NavDropdown.Item>
                            {isClaimCreator&&
                            <NavDropdown.Item onClick={()=>setNewClaimFormIsOpen(true)}>Add New</NavDropdown.Item>
                            }
                        </NavDropdown>
                        <Row>
                            <Col className='login-menu-item'><SignOutButton /></Col>
                            {graphData&&
                            <Col className='logged-in-user-name'>
                                <p>{graphData.givenName+' '+graphData.surname}</p>
                            </Col>
                            }
                        </Row>
                    </>
                    :
                    <div>
                        <SignInButton />
                    </div>
                    }
            
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    </>
  )
}

export default Header