import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import DropdownDebtorStatus from './DropdownDebtorStatus';
import useFetch from '../Utilities/useFetch';
import FuncFetch from '../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';
import ReactSelectCreatable from 'react-select/creatable';
import { Alert, Spinner } from 'react-bootstrap';
import ErrorBoundary from '../Auth/ErrorBoundary';

function StatusChangeModal({
    show,
    setShow,
    account,
    onChange,
    updateNCDAndActivities
}) {
    const [debtorStatus, setDebtorStatus] = useState();
    const [statusReason, setStatusReason] = useState();
    const [statusReasonOptions, setStatusReasonOptions] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const {instance, accounts} = useMsal();
    const {REACT_APP_API_URL} = process.env;
    const { data: reasons } = useFetch(true,`${REACT_APP_API_URL}/Claims/GetStatusReasons`,'GET')

    useEffect(()=>{
        if(reasons){
            let reasonOptions = reasons.map(reason=>{
                return {value:reason.comment,label:reason.comment}
            })
            setStatusReasonOptions(reasonOptions)
        }
    },[reasons])

    useEffect(()=>{
        if(account&&account.account?.debtorStatus){
            setDebtorStatus(account.account.debtorStatus)
        }
    },[account])

    const handleChange = (e) => { 
        if(onChange){
            onChange(e)
            setError(null)
        }
        setDebtorStatus(e.value)
     }

    const updateStatus = () => { 
        setError(null)
        setLoading(true)
        if(!debtorStatus){
            setError('Please select a status')
            setLoading(false)
            return
        }else if(!statusReason){
            setError('Please select a status reason')
            setLoading(false)
            return
        }
        FuncFetch(`${REACT_APP_API_URL}/Claims/PutDebtorStatus`,{debtorid:account.account.debtorID,status:debtorStatus,note:statusReason.value},'PUT',null,instance,accounts)
        .then(response=>{
            // When MIM status is selected the collector changes to Insurance
            // Call updateNCDAndActivities in collectorClaimModal to update the 
            // fields that the backend changed, including the collector
            setLoading(false)
            updateNCDAndActivities(response)
            setShow(false)
        })
        .catch(error=>{
            setError('Something went wrong please try again')
            console.error(error)
            setLoading(false)
        })
     }

  return (
    <Modal show={show} onHide={setShow}>

        <Modal.Header closeButton>
            <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <ErrorBoundary>
                <Form.Label>Debtor Status</Form.Label>
                <DropdownDebtorStatus 
                    debtorid={account.account?.debtorID}
                    defaultStatus={account.account?.debtorStatus} 
                    onChange={handleChange} 
                />
                <Form.Label>Status Reason</Form.Label>
                <ReactSelectCreatable
                    options={statusReasonOptions}
                    value={statusReason}
                    onChange={(e)=>setStatusReason(e)}
                />
                {error?
                    <Alert variant="danger">{error}</Alert>
                :null}
            </ErrorBoundary>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={()=>setShow(false)}>
                Close
            </Button>
            <Button variant="primary" onClick={()=>updateStatus()}>
                {loading?<Spinner animation='border' size="sm"/>:'Update Status'}
            </Button>
        </Modal.Footer>

    </Modal>
  )
}

export default StatusChangeModal