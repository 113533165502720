import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import ReactSelect from 'react-select';
import './PaymentHistoryForm.css';
import FuncFetch from '../../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';
import useFetch from '../../Utilities/useFetch';

const PaymentHistoryForm = ({
    defaultFormData,
    projects,
    loadingProjects,
    readOnly,
    debtorID,
    updateNCDAndActivities
}) => {
    const [formData, setFormData] = useState({
        checkStatus: null,
        collection_Type: null,
        collector: null,
        dateReceived: null,
        dateRemitted: null,
        debtorID: debtorID,
        deposit_Date: null,
        fee_Date: null,
        fee_Rate: null,
        memo: null,
        payor: null,
        paymentAmount: null,
        paymentDate: null,
        paymentMethod: null,
        receiver: null,
        remit_Amount: null,
        remit_Check: null,
        remitCheckClear: false
    });
    const {data:collectors,loading:loadingCollectors} = useFetch(true,`${process.env.REACT_APP_API_URL}/Claims/GetCollectors`,'GET')

    const [checkStatusOptions] = useState([
        {value:"Account Closed",label:"Account Closed"},
        {value:"Current",label:"Current"},
        {value:"Decline",label:"Decline"},
        {value:"Do Not Remit",label:"Do Not Remit"},
        {value:"Failed",label:"Failed"},
        {value:"Hold",label:"Hold"},
        {value:"NSF",label:"NSF"},
        {value:"Posted",label:"Posted"},
        {value:"Refunded",label:"Refunded"},
        {value:"Returned",label:"Returned"},
        {value:"Stop-Pay",label:"Stop-Pay"},
        {value:"Void",label:"Void"}])
    const [paymentMethodOptions] = useState([
        { value: "Wire-Transfer", label: "Wire-Transfer" },
        { value: "Cashiers Check", label: "Cashiers Check" },
        { value: "Check", label: "Check" },
        { value: "DRAFT", label: "DRAFT" },
        { value: "Money-Order", label: "Money-Order" },
        { value: "EFT", label: "EFT" },
        { value: "Credit Card", label: "Credit Card" },
        { value: "ACH", label: "ACH" }
    ])
    const [recieverOptions] = useState([
        {value:"1",label:"1"},
        {value:"2",label:"2"}
    ])
    const [payorOptions] = useState([
        {value:"CLMT",label:"CLMT"},
        {value:"RO",label:"RO"},
        {value:"Other",label:"Other"}
    ])
    const [collectorOptions, setCollectorOptions] = useState();
    const [projectOptions, setProjectOptions] = useState();
    const {instance, accounts} = useMsal();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if(collectors){
            setCollectorOptions(collectors.map(collector => { return { value: collector.key, label: collector.value } }));
        }
        if(projects){
            setProjectOptions(projects.map(project => { return { value: project.key, label: project.value } }));
        }
    }, [collectors, projects]);

    useEffect(() => {
        if (defaultFormData&&Object.keys(defaultFormData).length>0) {
            setFormData(defaultFormData);
        }
    }, [defaultFormData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleReactSelectChange = (value, name) => {
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        setLoading(true);
        setSuccess(false);
        setError(null);
        e.preventDefault();
    
        const requiredFields = [
            'paymentDate',
            'paymentAmount',
            'checkStatus',
            'paymentMethod',
            'receiver',
        ];
    
        const missingFields = requiredFields.filter(field => {
            return !formData[field]
        });
    
        if (missingFields.length > 0) {
            setError(`Missing required fields: ${missingFields.join(', ')}`);
            setLoading(false);
            return;
        }
    
        FuncFetch(`${process.env.REACT_APP_API_URL}/Claims/PostPayment`, null, 'POST', formData, instance, accounts)
            .then(response => {
                response.unshift({})
                updateNCDAndActivities({paymentHistory:response});
                setLoading(false);
                setSuccess(true);
            })
            .catch(error => {
                setLoading(false);
                setError('There was an issue, please try again');
                console.error(error);
            });
    };

    const filterOption = (option, inputValue) => {
        if(option.label){
            return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
        }else{
            return false
        }
      };

    return (
        <Form className='payment-form' onSubmit={handleSubmit} >
            <Form.Group controlId="paymentDate">
                <Form.Label>Payment Date<span className='required-field'>*</span></Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="date"
                    name="paymentDate"
                    value={formData.paymentDate?formData.paymentDate.split('T')[0]:''}
                    onChange={handleChange}
                    disabled={readOnly}
                    max={new Date().toISOString().split('T')[0]}
                />
            </Form.Group>

            <Form.Group controlId="paymentAmount">
                <Form.Label>Payment Amount<span className='required-field'>*</span></Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="number"
                    step=".01"
                    name="paymentAmount"
                    value={formData.paymentAmount}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="checkStatus">
                <Form.Label>Check Status<span className='required-field'>*</span></Form.Label>
                <ReactSelect
                    className='payment-form-input'
                    as="select"
                    name="checkStatus"
                    value={formData.checkStatus?checkStatusOptions.find(checkStatus=>checkStatus.value===formData.checkStatus):undefined}
                    onChange={(e)=>handleReactSelectChange(e.value,'checkStatus')}
                    isDisabled={readOnly}
                    options={checkStatusOptions}
                    filterOption={filterOption}
                />
            </Form.Group>

            <Form.Group controlId="paymentMethod">
                <Form.Label>Payment Method<span className='required-field'>*</span></Form.Label>
                <ReactSelect
                    className='payment-form-input'
                    as="select"
                    name="paymentMethod"
                    value={formData.paymentMethod?paymentMethodOptions.find(paymentMethod=>paymentMethod.value===formData.paymentMethod):undefined}
                    onChange={(e)=>handleReactSelectChange(e.value,'paymentMethod')}
                    isDisabled={readOnly}
                    options={paymentMethodOptions}
                    filterOption={filterOption}
                />
            </Form.Group>

            <Form.Group controlId="receiver">
                <Form.Label>Receiver<span className='required-field'>*</span></Form.Label>
                <ReactSelect
                    className='payment-form-input'
                    as="select"
                    name="receiver"
                    value={recieverOptions?recieverOptions.find(reciever=>reciever.value===formData.receiver):undefined}
                    onChange={(e)=>handleReactSelectChange(e.value,'receiver')}
                    isDisabled={readOnly}
                    options={recieverOptions}
                    filterOption={filterOption}
                />
            </Form.Group>

            <Form.Group controlId="dateReceived">
                <Form.Label>Date Received</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="date"
                    name="dateReceived"
                    value={formData.dateReceived?formData.dateReceived.split('T')[0]:''}
                    onChange={handleChange}
                    disabled={readOnly}
                    max={new Date().toISOString().split('T')[0]}
                />
            </Form.Group>

            <Form.Group controlId="dateRemitted">
                <Form.Label>Date Remitted</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="date"
                    name="dateRemitted"
                    value={formData.dateRemitted?formData.dateRemitted.split('T')[0]:''}
                    onChange={handleChange}
                    disabled={readOnly}
                    max={new Date().toISOString().split('T')[0]}
                />
            </Form.Group>

            <Form.Group controlId="remit_Check">
                <Form.Label>Remit Check</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="text"
                    name="remit_Check"
                    value={formData.remit_Check}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="remitCheckClear">
                <Form.Label>Remit Check Clear<span className='required-field'>*</span></Form.Label>
                <Form.Check
                    className='payment-form-input big-checkbox'
                    type="checkbox"
                    name="remitCheckClear"
                    checked={formData.remitCheckClear}
                    onChange={(e) => handleChange({ target: { name: 'remitCheckClear', value: e.target.checked } })}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="memo">
                <Form.Label>Memo</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="text"
                    name="memo"
                    value={formData.memo}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="collector">
                <Form.Label>Collector</Form.Label>
                {loadingCollectors ?
                    <Spinner animation="border" role="status" />
                    : (collectors&&collectorOptions) ?
                        <ReactSelect
                            className='payment-form-input'
                            value={formData.collector?collectorOptions.find(collector=>collector.value.toLowerCase()===formData.collector.toLowerCase()):undefined}
                            onChange={(e)=>handleReactSelectChange(e.value,'collector')}
                            options={collectorOptions}
                            isDisabled={readOnly}
                            filterOption={filterOption}
                        />
                        : null}
            </Form.Group>

            <Form.Group controlId="deposit_Date">
                <Form.Label>Deposit Date</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="date"
                    name="deposit_Date"
                    value={formData.deposit_Date?formData.deposit_Date.split('T')[0]:''}
                    onChange={handleChange}
                    max={new Date().toISOString().split('T')[0]}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="payor">
                <Form.Label>Payor</Form.Label>
                <ReactSelect
                    className='payment-form-input'
                    name="payor"
                    value={formData.payor?payorOptions.find(payor=>payor.value===formData.payor):undefined}
                    onChange={(e)=>handleReactSelectChange(e.value,'payor')}
                    isDisabled={readOnly}
                    options={payorOptions}
                    filterOption={filterOption}
                />
            </Form.Group>

            <Form.Group controlId="fee_Rate">
                <Form.Label>Fee Rate</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="number"
                    step=".01"
                    name="fee_Rate"
                    value={formData.fee_Rate}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="fee_Date">
                <Form.Label>Fee Date</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="date"
                    name="fee_Date"
                    value={formData.fee_Date?formData.fee_Date.split('T')[0]:''}
                    onChange={handleChange}
                    max={new Date().toISOString().split('T')[0]}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="remit_Amount">
                <Form.Label>Remit Amount</Form.Label>
                <Form.Control
                    className='payment-form-input'
                    type="number"
                    step=".01"
                    name="remit_Amount"
                    value={formData.remit_Amount}
                    onChange={handleChange}
                    disabled={readOnly}
                />
            </Form.Group>

            <Form.Group controlId="collection_Type">
                <Form.Label>Collection Type</Form.Label>
                {loadingProjects ?
                    <Spinner animation="border" role="status" />
                    : projects ?
                        <ReactSelect
                            className='payment-form-input'
                            value={formData.collection_Type?projectOptions.find(project=>project.value===formData.collection_Type):undefined}
                            onChange={(e)=>handleReactSelectChange(e.value,'collection_Type')}
                            options={projectOptions}
                            isDisabled={readOnly}
                            filterOption={filterOption}
                        />
                        : null}
            </Form.Group>
            {error?
            <Alert variant="danger">{error}</Alert>
            :success?
            <Alert variant="success">Success</Alert>
            :null}
            {!readOnly&&
            loading?
            <Spinner animation="border" role="status" />
            :
            <Button variant="primary" type="submit" disabled={readOnly}>
                Submit
            </Button>
            }
        </Form>
    );
};

export default PaymentHistoryForm;